
import { defineComponent } from "vue-demi";
import { useAPI } from '@/use';
import { AUTH_INPUTS } from '@/store/common/auth';
import CommonInputs from '@/components/common/inputs/index.vue';

export default defineComponent({
  name: 'AuthMain',
  data() {
    return {
      auth: AUTH_INPUTS
    }
  },
  methods: {
    async login() {
      const result = await useAPI().auth.loginMethod(this.auth.data);
      location.reload();
      this.$store.dispatch("loginAction", result.data);
    },
    route(route: string) {
      this.$router.push(route);
    },
  },
  components: {
    CommonInputs
  }
});
