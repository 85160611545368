
import { defineComponent } from "vue-demi";
import AuthMain from "@/components/auth/Main.vue";

export default defineComponent({
  name: "AuthView",
  components: {
    AuthMain,
  },
});
